import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";
import PriceTable from "../components/pricing/PriceTable";
import ReactTooltip from 'react-tooltip';

const Pricing = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Pricing" />
    <PriceTable/>
    <ReactTooltip place="right" effect="solid" html={true} class='bg-blue'/>
    <AmberScore/>
  </Layout>
)

export default Pricing