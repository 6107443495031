import React, { Component } from 'react';
import ScheduleDemoButton from '../scheduledemo/ScheduleDemoButton';
import info from '../../images/info-icon-2.svg'

export default class PriceTable extends Component {
    state = {
        categories : [
            {
                category_name: 'ANALYTICS',
                features: [
                    {feature_name: 'Real-Time Tenure Reachouts', starter: true, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Turnover Intent - PTM', starter: true, professional: true, enterprise: true, text: false, tooltip: 'Identify your at-risk employees and <br/> understand the reason behind their disengagement'},
                    {feature_name: 'Employee Experience Driver Framework - EN EX', starter: true, professional: true, enterprise: true, text: false, tooltip: "Driven by extensive employee experience research & developed in-house <br/>by inFeedo's People Science team, Amber's questions are set in a manner to <br/>initiate conversations and nudge employees to share honest feedback"},
                    {feature_name: 'Sentiment Analysis (Micro + Macro)', starter: true, professional: true, enterprise: true, text: false, tooltip: 'Understand if your employees are actually happy or unhappy'},
                    {feature_name: 'Organizational Mood Score', starter: true, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Response Rate Analysis', starter: true, professional: true, enterprise: true, text: false, tooltip: 'Measure and analyze the rate at which employees<br/> respond to Amber when she reaches out to them'},
                    {feature_name: 'Daily Insights', starter: true, professional: true, enterprise: true, text: false, tooltip: 'Get people insights pushed daily to your inbox'},
                    {feature_name: 'TextSens (Powered by in-house NLP engine)', starter: true, professional: true, enterprise: true, text: false, tooltip: "inFeedo's proprietary AI-based algorithm provides <br/>an in-depth textual analysis of employees responses"},
                    {feature_name: 'Fortnightly Reports', starter: false, professional: true, enterprise: true, text: false, tooltip: ''},
                ]
            },
            {
                category_name: 'PRODUCTIVITY',
                features: [
                    {feature_name: 'Automated 1:1 Meeting Scheduling', starter: true, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Documentation and Tracking Action', starter: true, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Export Raw Data', starter: true, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Demographic Report', starter: false, professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'Admin Access', starter: '5', professional: '50', enterprise: 'Unilimited', text: true,  tooltip: ''},
                ]
            },
            {
                category_name: 'SUPPORT',
                features: [
                    {feature_name: 'Launch & Implementation Assistance', starter: true, professional: true, enterprise: true, text: false,  tooltip: ''},
                    {feature_name: '24x7 Email Support', starter: true, professional: true, enterprise: true, text: false,  tooltip: ''},
                    {feature_name: 'Product Onboarding & Training', starter: '1', professional: '2', enterprise: '5', text: false,  tooltip: ''},
                    {feature_name: 'Customer Success Manager', starter: '', professional: 'Dedicated CSM', enterprise: 'Dedicated Sr. CSM', text: true,  tooltip: ''},
                    {feature_name: 'Rollout Strategy', starter: '', professional: 'Standard Plan', enterprise: 'Premium Plan', text: true,  tooltip: ''},
                ]
            },
            {
                category_name: 'CUSTOMIZATION',
                features: [
                    {feature_name: 'Question Bank Consultation', starter: false, professional: true, enterprise: true, text: false,  tooltip: "Consult with our People Science team to ensure Amber's<br/> question bank suits your organization's mission, vision and values"},
                    {feature_name: 'Post Launch Communications', starter: false, professional: false, enterprise: true, text: false,  tooltip: "Get access to inFeedo's design & marketing team to announce Amber internally"},
                    {feature_name: 'Multiple Cohorts: Key employee segments', starter: false, professional: false, enterprise: true, text: false,  tooltip: "Create key employee segments based on geography, hierarchy, role, and so on,<br/> and customize Amber's questions accordingly"},
                ]
            },
            {
                category_name: 'INTEGRATION',
                features: [
                    {feature_name: 'HRMS Integration', starter: 'Basic', professional: 'Custom', enterprise: 'Advanced', text: true,  tooltip: ''},
                    {feature_name: 'SSO Integration', starter: '', professional: '$$$', enterprise: true, text: true,  tooltip: ''},
                    {feature_name: 'Additional Integrations', starter: '', professional: '$$$', enterprise: '$$$', text: true,  tooltip: 'Intergration with Facebook Workplace, Slack, Skype and other chat platforms'},
                ]
            },
            {
                category_name: 'ADDED MODULES',
                features: [
                    {feature_name: 'Anonymous Bat', starter: true, professional: true, enterprise: true, text: false,  tooltip: "Deploy Anonymous Bat, Amber's digital colleague,<br/> who is available 24x7 as your ombudsperson to handle<br/> highly sensitive issues discreetly"},
                    {feature_name: 'SMS/Text Reminders', starter: '$$$', professional: '$$$', enterprise: '$$$', text: true, tooltip: ''},
                    {feature_name: 'Exit Interactions', starter: '$$$', professional: '$$$', enterprise: true, text: true,  tooltip: "Capture actionable feedback during and after an <br/>employee exits with inFeedo's new off-boarding module"},
                    {feature_name: 'Early Access to Beta Products', starter: '', professional: true, enterprise: true, text: false, tooltip: ''},
                    {feature_name: 'One-Time Surveys', starter: false, professional: true, enterprise: true, coming_soon: true, text: false, tooltip: ''},
                    {feature_name: 'Candidate Experience', starter: false, professional: true, enterprise: true, coming_soon: true, text: false, tooltip: ''},
                    {feature_name: 'Moments That Matter', starter: false, professional: true, enterprise: true, coming_soon: true, text: false, tooltip: ''},
                    {feature_name: 'Executive P.O.V. Insights', starter: false, professional: true, enterprise: true, coming_soon: true, text: false, tooltip: ''},
                ]
            }
        ]
    }
    render() {
        const { categories } = this.state;
        return (
            <div className="has-text-centered">
                <div className="pd-10">
                    <br/>
                    <br/>
                    <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-10">No matter what your needs, we have the plan for you.</h3>
                    <br/>
                    <br/>
                    <div id="stickyTableHead" className="sticky columns column is-11 mar-auto no-margin-padding-bottom is-hidden-mobile"> 
                    <table className="table is-fullwidth price-card">
                            <thead>
                                <tr className="plan-row is-hidden-mobile">
                                    <th className="td-width-1"></th>
                                    <th className="td-width-2">
                                        <p className="is-gold mg-bottom-5">STARTER</p>
                                        <p className="is-size-7 has-text-weight-normal mg-bottom-5">Ideal for 200-600 Employees</p>
                                        <p className="is-blue-text mg-bottom-20">Starts at $999/Mo</p>
                                        <ScheduleDemoButton is_small={true} button_text={'Get Started'}/>
                                    </th>
                                    <th className="td-width-3">
                                        <p className="is-sky mg-bottom-5">PROFESSIONAL</p>
                                        <p className="is-size-7 has-text-weight-normal mg-bottom-5">Ideal for 650-5000 Employees</p>
                                        <p className="is-blue-text mg-bottom-20">Starts at $1499/Mo*</p>
                                        <ScheduleDemoButton is_small={true} button_text={'Get Started'}/>
                                    </th>
                                    <th className="td-width-4-2">
                                        <p className="is-red mg-bottom-5">ENTERPRISE</p>
                                        <p className="is-size-7 has-text-weight-normal mg-bottom-5">Ideal for 5050+ Employees</p>
                                        <p className="is-blue-text mg-bottom-20">Custom*</p>
                                        <ScheduleDemoButton is_small={true} button_text={'Get Started'}/>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="responsive-table columns column is-11 mar-auto flex-wrap">
                        <div className="column is-12 is-hidden-tablet mg-bottom-20 is-paddingless">
                            <div className="price-mobile">
                                <p className="is-gold mg-bottom-5 has-text-weight-bold">STARTER</p>
                                <p className="is-size-7 mg-bottom-5">Ideal for 200-600 Employees</p>
                                <p className="is-blue-text has-text-weight-bold">Starts at $999/Mo</p>
                            </div>
                            <div className="price-mobile">
                                <p className="is-sky mg-bottom-5 has-text-weight-bold">PROFESSIONAL</p>
                                <p className="is-size-7 mg-bottom-5">Ideal for 650-5000 Employees</p>
                                <p className="is-blue-text has-text-weight-bold">Starts at $1499/Mo*</p>
                            </div>
                            <div className="price-mobile">
                                <p className="is-red mg-bottom-5 has-text-weight-bold">ENTERPRISE</p>
                                <p className="is-size-7 mg-bottom-5">Ideal for 5050+ Employees</p>
                                <p className="is-blue-text has-text-weight-bold">Custom*</p>
                            </div><br/><br/><br/>
                            <span className="mg-right-10"><span className="bg-gold"></span> &nbsp;<span className="font-11 has-text-weight-bold">STARTER</span></span>
                            <span className="mg-right-10"><span className="bg-sky"></span> &nbsp;<span className="font-11 has-text-weight-bold">PROFESSIONAL</span></span>
                            <span><span className="bg-red"></span> &nbsp;<span className="font-11 has-text-weight-bold">ENTERPRISE</span></span>
                        </div>
                        <table className="table is-fullwidth price-card">
                            <tbody>
                                {categories.map(category => (
                                    <React.Fragment>
                                    <tr key={category.category_name} className="heading-row">
                                        <td>
                                            {category.category_name}
                                        </td>
                                        <td className="is-hidden-mobile"></td>
                                        <td className="is-hidden-mobile"></td>
                                        <td className="is-hidden-mobile"></td>
                                    </tr>
                                    {category.features.map(feature => (
                                        <tr key={feature.feature_name} className="feature-row">
                                            <td className="td-width-1">
                                                {feature.coming_soon 
                                                    ? <span className="has-text-grey"><i><span className="soon">Coming Soon - </span></i> {feature.feature_name}</span>  
                                                    : <span>{feature.feature_name} {feature.tooltip ? <span className="is-hidden-mobile pd-top-5" data-tip={`<p className="has-text-left">${feature.tooltip}</p>`}>&nbsp;<img src={info} width="15px" alt="info" className="info-icon"/></span> : null}<br className="is-hidden-tablet"/></span>
                                                }
                                                <span className="is-pulled-right is-hidden-tablet mg-4">
                                                    {feature.enterprise === true ? <span className="bg-red"></span> : null}
                                                    {feature.enterprise === false || feature.enterprise === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.enterprise.length ? <span className="font-12 is-red">{feature.enterprise.includes('$') ? <span>{feature.enterprise}</span>: <span className="has-text-weight-bold">{feature.enterprise}</span>}</span> : null}
                                                </span>
                                                <span className="is-pulled-right is-hidden-tablet mg-4">
                                                    {feature.professional === true ? <span className="bg-sky"></span> : null}
                                                    {feature.professional === false || feature.professional === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.professional.length ? <span className="font-12 is-sky">{feature.professional.includes('$') ? <span>{feature.professional}</span>: <span className="has-text-weight-bold">{feature.professional}</span>}</span> : null}
                                                </span>
                                                <span className="is-pulled-right is-hidden-tablet mg-4">
                                                    {feature.starter === true ? <span className="bg-gold"></span> : null}
                                                    {feature.starter === false || feature.starter === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.starter.length ? <span className="font-12 is-gold">{feature.starter.includes('$') ? <span>{feature.starter}</span>: <span className="has-text-weight-bold">{feature.starter}</span>}</span> : null}
                                                </span>
                                            </td>
                                            <td className="has-text-centered is-hidden-mobile td-width-2">
                                                {feature.starter === true ? <span className="bg-gold"></span> : null}
                                                {feature.starter === false || feature.starter === '' ? <span className="dash">&mdash;</span> : null}
                                                {feature.starter.length ? <span className="font-14">{feature.starter}</span> : null}
                                            </td>
                                            <td className="has-text-centered is-hidden-mobile td-width-3">
                                                {feature.professional === true ? <span className="bg-sky"></span> : null}
                                                {feature.professional === false || feature.professional === '' ? <span className="dash">&mdash;</span> : null}
                                                {feature.professional.length ? <span className="font-14">{feature.professional}</span> : null}
                                            </td>
                                            <td className="has-text-centered is-hidden-mobile td-width-4">
                                                {feature.enterprise === true ? <span className="bg-red"></span> : null}
                                                {feature.enterprise === false || feature.enterprise === '' ? <span className="dash">&mdash;</span> : null}
                                                {feature.enterprise.length ? <span className="font-14">{feature.enterprise}</span> : null}
                                            </td>
                                        </tr>
                                    ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <p className="is-size-7 has-text-centered">*Calculated Per Employee and Billed Annually</p>
                </div>
            </div>
        )
    }
}


